import { Box } from "@mui/material";
import Navbar from "../navbar";

const HomePage = () => {
  return (
    <Box>
      <Navbar />
    </Box>
  );
};
export default HomePage;
